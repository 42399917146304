import UserService, { UserResponse } from "apis/user";
import { ReactNode, useEffect, useState } from "react";
import { createContext } from "react";
import { getUserName } from "services/authenticate";
import { usePostHog } from "posthog-js/react";

//--PERMISSIONS---
// 1: Treasury
// 2: Torque

export enum Permissions {
  TREASURY = 1,
  TORQUE = 2,
}

// Define the shape of the context state
interface UserContextState {
  isAuthenticated: boolean;
  permissions: number[];
  userDetails: UserResponse | null;
  isGoogleLogin: boolean;
  // Add your state properties here, for example:
}
// Define the shape of the context value
export interface UserContextValue extends UserContextState {
  // Add your functions here, for example:
  //   createInvoice: (data: any) => Promise<void>; // Replace 'any' with a more specific type
  //   updateInvoiceData: (
  //     data: Partial<InvoiceContextState["invoiceData"]>
  //   ) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  cleanUpUser: () => void;
  getUserDetails: () => void;
  setIsGoogleLogin: (isGoogleLogin: boolean) => void;
}
export const UserContext = createContext<UserContextValue | undefined>(undefined);
export const UserProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState<UserResponse | null>(null);
  const [permissions, setPermissions] = useState<number[]>([]);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const email = localStorage.getItem("email");
  const posthog = usePostHog();
  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    if (email && accessToken) {
      posthog.identify(email, {
        email: email
      });
    }
  }, [email, posthog]);
  useEffect(() => {
    getUserName(email).then(name => {
      setIsAuthenticated(true);
    }).catch(error => {
      console.error("Error fetching user name", error);
    });
  }, [email]);
  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    if (isAuthenticated) {
      getUserDetails();
    }
  }, [isAuthenticated]);
  const getUserDetails = async () => {
    try {
      const response = await UserService.getUserDetails();
      console.log("response", response.data);
      setUserDetails(response?.data || null);
      setPermissions(response?.data?.activeServices || []);
    } catch (error) {
      console.error("Error fetching user details", error);
    }
  };
  const cleanUpUser = () => {
    setIsAuthenticated(false);
    setUserDetails(null);
    setPermissions([]);
    setIsGoogleLogin(false);
  };
  const value: UserContextValue = {
    permissions,
    userDetails,
    isAuthenticated,
    setIsAuthenticated,
    cleanUpUser,
    getUserDetails,
    isGoogleLogin,
    setIsGoogleLogin
  };

  //@ts-ignore

  return <UserContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="UserProvider" data-sentry-source-file="UserProvider.tsx">{children}</UserContext.Provider>;
};