import { KYCService } from "apis/kyc";
import { useUser } from "hooks/useUser";
import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { BusinessDetailsForm, BankDetailsForm, DocumentUploadForm, KYCFormData, UserDetailsForm, PartnershipDetailsForm, calculateStepCompletion } from "types/form";
import { convertToFormData, extractFormFields, nullifyKeys, objectToFormData, Path } from "utils/form-extractor";
import { formFieldsConfig } from "validations/kyc";
import { BusinessTypeEnum, BusinessTypeProps, KYCJourneySteps, KYCFormDataKeys, KYCJourneyStepsProps } from "constants/kyc";
import { DefaultDialogBox } from "components";
import { logout } from "services/authenticate";
import { useInit } from "hooks/useInit";
import { PATH_AUTH } from "router/path";
import ReviewKYC from "sections/KYC-V2/components/ReviewKYC";
import KYCSubmitted from "sections/KYC-V2/components/KYCSubmitted";
import { usePostHog } from "posthog-js/react";
import { determineKYCStatus } from "utils/common";

// Define initial states as constants (add this near the top of the file)
const INITIAL_COMPLETION_PERCENTAGE = {
  [KYCJourneySteps.userDetails]: 0,
  [KYCJourneySteps.businessDetails]: 0,
  [KYCJourneySteps.bankDetails]: 0,
  [KYCJourneySteps.documents]: 0,
  [KYCJourneySteps.partnersDetails]: 0,
  [KYCJourneySteps.directorDetails]: 0
};
const INITIAL_FORM_DATA = {
  userDetails: null,
  businessDetails: null,
  bankDetails: null,
  documents: null,
  partnersDetails: null
};
interface KYCContextType {
  businessType: BusinessTypeProps;
  setBusinessType: (businessType: BusinessTypeProps) => void;
  hasJourneyStarted: boolean;
  setHasJourneyStarted: (hasJourneyStarted: boolean) => void;
  currentStep: number;
  serverCompletionPercentage: Record<KYCJourneySteps, number>;
  setCurrentStep: (currentStep: number) => void;
  journey: KYCJourneySteps[];
  handleNext: () => void;
  handleBack: () => void;
  formData: Partial<KYCFormData>;
  updateFormData: (step: KYCJourneySteps, data: Partial<KYCFormData[keyof KYCFormData]>, isDirty: boolean) => void;
  handleSubmitKYC: (keys: KYCFormDataKeys[]) => Promise<any>;
  onSubmitUserDetails: (data: UserDetailsForm) => Promise<any>;
  onSubmitBusinessDetails: (data: BusinessDetailsForm) => Promise<any>;
  onSubmitBankDetails: (data: BankDetailsForm) => Promise<any>;
  onSubmitDocumentUpload: (data: DocumentUploadForm) => Promise<any>;
  onSubmitPartnerDirectorDetails: (data: {
    isUbo: number;
  }) => Promise<any>;
  onDeleteDirectorPartner: (id: number) => Promise<any>;
  onCreateDirectorPartner: (data: PartnershipDetailsForm) => Promise<any>;
  getKYCDetails: () => Promise<any>;
  completionPercentage: Record<KYCJourneySteps, number>;
  setOpenSubmitKYCDialog: (open: boolean) => void;
  onTriggerSubmitKYC: () => Promise<any>;
  onSelectBusinessType: (businessType: BusinessTypeProps) => Promise<any>;
  resetFormData: () => void;
  setOpenReview: (open: boolean) => void;
  serverData: any;
}
export const KYCContext = createContext<KYCContextType | undefined>(undefined);
export function KYCProvider({
  children
}: {
  children: ReactNode;
}) {
  const {
    isAuthenticated,
    userDetails
  } = useUser();
  const [businessType, setBusinessType] = useState<BusinessTypeProps>(BusinessTypeEnum.company);
  const posthog = usePostHog();
  const [openReview, setOpenReview] = useState(false);
  const {
    getUserDetails,
    cleanUpUser
  } = useUser();
  const {
    cleanUp
  } = useInit();
  const [openSubmitKYCDialog, setOpenSubmitKYCDialog] = useState(false);
  const [hasJourneyStarted, setHasJourneyStarted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [serverData, setServerData] = useState<any>(null);
  const [formData, setFormData] = useState<Partial<KYCFormData>>(INITIAL_FORM_DATA);
  const [serverCompletionPercentage, setServerCompletionPercentage] = useState<Record<KYCJourneySteps, number>>(INITIAL_COMPLETION_PERCENTAGE);
  const [completionPercentage, setCompletionPercentage] = useState<Record<KYCJourneySteps, number>>(INITIAL_COMPLETION_PERCENTAGE);

  // Define journey steps based on business type
  const journeyMap = {
    [BusinessTypeEnum.company]: [KYCJourneySteps.userDetails, KYCJourneySteps.businessDetails, KYCJourneySteps.directorDetails, KYCJourneySteps.bankDetails, KYCJourneySteps.documents],
    [BusinessTypeEnum.proprietorship]: [KYCJourneySteps.userDetails, KYCJourneySteps.businessDetails, KYCJourneySteps.bankDetails, KYCJourneySteps.documents],
    [BusinessTypeEnum.freelancer]: [KYCJourneySteps.userDetails, KYCJourneySteps.businessDetails, KYCJourneySteps.bankDetails, KYCJourneySteps.documents],
    [BusinessTypeEnum.partnership]: [KYCJourneySteps.userDetails, KYCJourneySteps.businessDetails, KYCJourneySteps.partnersDetails, KYCJourneySteps.bankDetails, KYCJourneySteps.documents],
    [BusinessTypeEnum.llp]: [KYCJourneySteps.userDetails, KYCJourneySteps.businessDetails, KYCJourneySteps.partnersDetails, KYCJourneySteps.bankDetails, KYCJourneySteps.documents]
  };
  const journey = journeyMap[businessType] || [];
  console.log("completionPercentage:", completionPercentage);
  console.log("serverCompletionPercentage:", serverCompletionPercentage);
  console.log("formData:", formData, businessType);
  useEffect(() => {
    if (isAuthenticated && !!userDetails?.kycStatus) {
      console.log("getting kyc details");
      getKYCDetails();
    }
  }, [isAuthenticated, userDetails]);
  useEffect(() => {
    if (serverData) {
      console.log("updating serverData:", serverData);
      setServerCompletionPercentage(Object.keys(KYCJourneySteps).filter(step => step !== KYCJourneySteps.directorDetails).reduce((acc, step) => {
        acc[(step as KYCJourneySteps)] = calculateStepCompletion((step as KYCJourneySteps), serverData[step], businessType);
        return acc;
      }, ({} as Record<KYCJourneySteps, number>)));
    }
  }, [serverData]);
  const updateFormData = React.useCallback((step: KYCJourneySteps, data: any, isDirty: boolean) => {
    setFormData(prev => {
      console.log("3rd", prev, data);
      const updatedData = {
        ...prev,
        [step]: {
          ...prev[step],
          ...(data as (typeof prev)[typeof step])
        }
      };
      if (isDirty) {
        const newPercentage = calculateStepCompletion(step, updatedData[step], businessType);
        setCompletionPercentage(prev => ({
          ...prev,
          [step]: newPercentage
        }));
      }
      return updatedData;
    });
  }, [businessType]);
  const handleNext = () => {
    if (currentStep < journey.length - 1) {
      setCurrentStep(curr => curr + 1);
    }
  };
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(curr => curr - 1);
    }
  };
  async function handleSubmitKYC(keys: KYCFormDataKeys[], overrideFormData?: any) {
    try {
      // Create a copy of formData and delete the partnerDetails key
      const formDataCopy = {
        ...formData
      };
      //@ts-ignore
      //   delete formDataCopy.partnersDetails;
      const formFields = extractFormFields(formDataCopy, keys);
      console.log("formFields", formFields);
      const essentialFields = {
        ...formFields,
        ...overrideFormData,
        kycType: businessType.toUpperCase(),
        KYCJourneyStep: journey[currentStep]
      };
      const nullifiedFields = nullifyKeys(essentialFields, ["bankingProofDocument", "proofOfBusinessDocument", "panDocument", "partnershipDeedDocument"]);
      console.log("nullifiedFields", nullifiedFields);
      const formDataToSubmit = objectToFormData(nullifiedFields);
      console.log("formDataToSubmit", formDataToSubmit);
      const response = await KYCService.CreateKYC(formDataToSubmit);
      await getKYCDetails();
      return response.data;
    } catch (error) {
      console.error("Error submitting KYC:", error);
      throw error;
    }
  }
  const onSubmitUserDetails = async (data: UserDetailsForm) => {
    try {
      posthog.capture("user_details_submitted");
      const response = await handleSubmitKYC(["userDetails.fullName", "userDetails.email", "userDetails.phoneNumber"]);
      handleNext();
      return response;
      // Handle form submission
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmitBusinessDetails = async (data: BusinessDetailsForm) => {
    try {
      posthog.capture("business_details_submitted");
      const response = await handleSubmitKYC(["businessDetails.businessDescription", "businessDetails.companyWebsiteLink", "businessDetails.hasWebsite", "businessDetails.doingBusinessFlag", "businessDetails.doingBusinessAs", "businessDetails.socialMediaLink", "businessDetails.businessAddress"]);
      handleNext();
      return response;
      // Handle form submission
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmitBankDetails = async (data: BankDetailsForm) => {
    // Handle form submission
    try {
      posthog.capture("bank_details_submitted");
      const response = await handleSubmitKYC(["bankDetails.bankName", "bankDetails.bankAccountNumber", "bankDetails.bankAccountHolderName", "bankDetails.ifscCode", "bankDetails.bankingProofDocument"]);
      handleNext();
      return response;
      // Handle form submission
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmitDocumentUpload = async (data: DocumentUploadForm) => {
    // Handle form submission
    try {
      posthog.capture("document_upload_submitted");
      const response = await handleSubmitKYC(["documents.proofOfBusinessDocument", "documents.proofOfBusinessDocumentNumber", "documents.panNumber", "documents.nameOnPan", "documents.panDocument", "documents.partnershipDeedDocument"]);
      handleNext();
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmitPartnerDirectorDetails = async (data: {
    isUbo: number;
  }) => {
    try {
      posthog.capture("partner_director_details_submitted");
      const payload = {
        ...data,
        kycType: businessType.toUpperCase()
      };
      const response = await onUpdateUBO(payload);
      await getKYCDetails();
      handleNext();
      return response;
    } catch (e) {}
  };
  const onCreateDirectorPartner = async (data: PartnershipDetailsForm) => {
    try {
      posthog.capture("partner_director_details_submitted");
      const payload = {
        ...data,
        kycType: businessType.toUpperCase()
      };
      const formDataToSubmit = convertToFormData(payload);
      const response = await KYCService.CreatePartnerKYC(formDataToSubmit);
      await getKYCDetails();
      return response.data;
    } catch (error) {
      console.error(error);
    }
    // Handle form submission
  };
  const onDeleteDirectorPartner = async (id: number) => {
    try {
      const response = await KYCService.DeletePartnerKYC(id);
      posthog.capture("partner_director_deleted");
      await getKYCDetails();
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const onUpdateUBO = async (data: {
    isUbo: number;
  }) => {
    try {
      const response = await KYCService.UpdateUBO(data.isUbo);
      posthog.capture("ubo_updated");
      await getKYCDetails();
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const onTriggerSubmitKYC = async () => {
    try {
      const response = await KYCService.TriggerSubmitKYC();
      posthog.capture("kyc_submitted");
      const userReponse = await getUserDetails();
      setOpenSubmitKYCDialog(false);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const getKYCDetails = async () => {
    const response = await KYCService.GetKYCDetails();
    if (response.data) {
      console.log("kyc type", !!response.data.userDetails.kycType);
      console.log("4th");
      setFormData(response.data);
      setServerData({
        ...response.data,
        _timestamp: Date.now()
      });
      console.log("kyc typess", businessType, response.data.userDetails.kycType);
      if (!businessType || !!response.data.userDetails.kycType) {
        console.log("entered", !!response.data.userDetails.kycType);
        setHasJourneyStarted(!!response.data.userDetails.kycType);
        setBusinessType(response.data.userDetails.kycType?.toLowerCase());
        setCompletionPercentage(Object.keys(KYCJourneySteps).filter(step => step !== KYCJourneySteps.directorDetails).reduce((acc, step) => {
          acc[(step as KYCJourneySteps)] = calculateStepCompletion((step as KYCJourneySteps), response.data[step], businessType);
          return acc;
        }, ({} as Record<KYCJourneySteps, number>)));
      }
    }
    console.log(response.data);
    return response.data;
  };
  const onSelectBusinessType = async (businessType: BusinessTypeProps) => {
    try {
      posthog.capture("business_type_selected", {
        business_type: businessType
      });
      const payload = {
        kycType: businessType.toUpperCase(),
        KYCJourneyStep: journey[currentStep],
        fullName: `${userDetails?.accountData?.firstName || ""} ${userDetails?.accountData?.lastName || ""}`,
        email: userDetails?.accountData?.email,
        doingBusinessAs: userDetails?.accountData?.companyName,
        phoneNumber: userDetails?.accountData.phoneNumber?.slice(3)
      };
      const formDataToSubmit = objectToFormData(payload);
      console.log("formDataToSubmit", formDataToSubmit);
      const response = await KYCService.CreateKYC(formDataToSubmit);
      await getKYCDetails();

      // setFormData({
      //   ...formData,
      //   [KYCJourneySteps.userDetails]: {
      //     ...formData[KYCJourneySteps.userDetails],
      //     fullName: `${userDetails?.accountData?.firstName || ""} ${userDetails?.accountData?.lastName || ""}`,
      //     email: userDetails?.accountData?.email || "",
      //     phoneNumber: userDetails?.accountData.phoneNumber?.slice(3) || "",
      //     //@ts-ignore
      //     // kycType: businessType.toUpperCase(),
      //   },
      //   //@ts-ignore
      //   [KYCJourneySteps.businessDetails]: {
      //     ...formData[KYCJourneySteps.businessDetails],
      //     doingBusinessAs: userDetails?.accountData?.companyName || "",
      //   },
      // });
      setHasJourneyStarted(true);
    } catch (err) {}
  };
  const resetFormData = () => {
    setBusinessType(BusinessTypeEnum.company);
    setHasJourneyStarted(false);
    setCurrentStep(0);
    setServerData(null);
    setFormData(INITIAL_FORM_DATA);
    setServerCompletionPercentage(INITIAL_COMPLETION_PERCENTAGE);
    setCompletionPercentage(INITIAL_COMPLETION_PERCENTAGE);
    setOpenSubmitKYCDialog(false);
  };
  const value: KYCContextType = {
    businessType,
    setBusinessType,
    hasJourneyStarted,
    setHasJourneyStarted,
    currentStep,
    setCurrentStep,
    journey,
    handleNext,
    handleBack,
    formData,
    updateFormData,
    handleSubmitKYC,
    onSubmitUserDetails,
    onSubmitBusinessDetails,
    onSubmitBankDetails,
    onSubmitDocumentUpload,
    onSubmitPartnerDirectorDetails,
    onCreateDirectorPartner,
    onDeleteDirectorPartner,
    getKYCDetails,
    completionPercentage,
    setOpenSubmitKYCDialog,
    onTriggerSubmitKYC,
    serverCompletionPercentage,
    onSelectBusinessType,
    resetFormData,
    setOpenReview,
    serverData
  };
  return <KYCContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="KYCProvider" data-sentry-source-file="KYCProvider.tsx">
      {children}
      <DefaultDialogBox open={openSubmitKYCDialog} title="Submit KYC details" description="You have submitted your KYC details. Do you want to proceed?" onClose={() => setOpenSubmitKYCDialog(false)} onSubmit={onTriggerSubmitKYC} data-sentry-element="DefaultDialogBox" data-sentry-source-file="KYCProvider.tsx" />
      {/* <KYCSubmitted
        open={userDetails?.kycStatus === "SUBMITTED"}
        onSubmit={() => {
          logout();
          cleanUpUser();
          cleanUp();
          localStorage.clear();
          window.location.href = PATH_AUTH.login;
        }}
       /> */}
      <ReviewKYC open={openReview} onSubmit={onTriggerSubmitKYC} onClose={() => setOpenReview(false)} excludedKeys={["doingBusinessFlag", "panMethod", "proofOfBusinessMethod"]} data-sentry-element="ReviewKYC" data-sentry-source-file="KYCProvider.tsx" />
    </KYCContext.Provider>;
}