import { BusinessTypeEnum, BusinessTypeProps, KYCJourneySteps } from "constants/kyc";

export interface UserDetailsForm {
    fullName: string;
    email: string;
    phoneNumber: string;
    // ... other user fields
  }


  export interface Address{
    streetAddressLine1: string;
    streetAddressLine2?: string | null;
    city: string;
    state: string;
    country: string;
    postalCode: string;
  }
  
  export interface BusinessDetailsForm {
    doingBusinessAs?: string;
    businessDescription: string;
    companyWebsiteLink?: string;
    hasWebsite: boolean;
    doingBusinessFlag: "1" | "0";
    socialMediaLink?: string;
    businessAddress:Address;
  }
  
  export interface BankDetailsForm {
    bankDetailsMethod:"ENTER_DETAILS" | "UPLOAD_PROOF";
    bankName?: string;
    bankAccountHolderName?: string;
    bankAccountNumber?: string;
    ifscCode?: string;
    bankingProofDocument?: File;
    // ... other bank fields
  }


  export interface PartnerDetails{
    partnerName: string;
    countryOfBirth: string;
    aadhaarDocument?: File | null;
    panDocument?: File | null;
    panNumber?: string;
    isUbo?:boolean;
    partnerId?:number;
  }

  export interface PartnershipDetailsForm {
    partners:PartnerDetails[];
    uboPartnerId:number;
  }
  
  export interface DocumentUploadForm {
    proofOfBusinessDocument?: File | null;
    proofOfBusinessDocumentNumber?: string;
    panDocument?: File | null;
    nameOnPan?: string;
    panNumber?: string;
    partnershipDeedDocument?: File | null;
    panMethod: "PROVIDE_DETAILS" | "UPLOAD_DOCUMENT";
    proofOfBusinessMethod: "PROVIDE_DETAILS" | "UPLOAD_DOCUMENT";
  }


  export interface KYCFormData{ 
    userDetails:UserDetailsForm|null;
    businessDetails:BusinessDetailsForm|null;
    bankDetails:BankDetailsForm|null;
    documents:DocumentUploadForm|null;
    partnersDetails:PartnerDetails[]|null;
    directorDetails:PartnerDetails[]|null;
    
  }



  export const calculateStepCompletion = (
    step: KYCJourneySteps,
    data: any,
    businessType: BusinessTypeProps
  ): number => {
    console.log('STEP:', step)
    if (!data) return 0;
  
    switch (step) {
      case KYCJourneySteps.userDetails:
        return calculateUserDetailsCompletion(data);
      case KYCJourneySteps.businessDetails:
        return calculateBusinessDetailsCompletion(data);
      case KYCJourneySteps.partnersDetails:
        return calculatePartnersDetailsCompletion(data);
      case KYCJourneySteps.directorDetails:
        return calculatePartnersDetailsCompletion(data);
      case KYCJourneySteps.bankDetails:
        return calculateBankDetailsCompletion(data);
      case KYCJourneySteps.documents:
        return calculateDocumentsCompletion(data, businessType);
      default:
        return 0;
    }
  };
  
  const calculateUserDetailsCompletion = (data: any): number => {
    const requiredFields = ['email', 'fullName', 'phoneNumber'];
    const weightPerField = 100 / requiredFields.length;
    
    return requiredFields.reduce((total, field) => {
      return total + (data[field] ? weightPerField : 0);
    }, 0);
  };
  
  const calculateBusinessDetailsCompletion = (data: any): number => {
    let totalFields = ['businessDescription'];
    let filledFields = data.businessDescription ? 1 : 0;
  
    // Handle doingBusinessAs based on flag
    if (data.doingBusinessFlag == 0) {  // Changed condition
      totalFields.push('doingBusinessAs');
      if (data.doingBusinessAs) filledFields++;
    }
  
    // ... existing code ...
    totalFields.push('websiteOrSocial');
    if (data.companyWebsiteLink || data.socialMediaLink) filledFields++;
  
    totalFields.push('businessAddress');
    const parsedAddress = typeof data.businessAddress === 'string' ? JSON.parse(data.businessAddress) : data.businessAddress;
    if (parsedAddress && 
        parsedAddress.streetAddressLine1 &&
        parsedAddress.city &&
        parsedAddress.state &&
        parsedAddress.country &&
        parsedAddress.postalCode) {
      filledFields++;
    }
  
    return (filledFields / totalFields.length) * 100;
  };
  
  const calculatePartnersDetailsCompletion = (data: any): number => {
    if (!Array.isArray(data) || data.length === 0) return 0;
  
    let completion = 0;
    
    // Check for minimum 2 partners (50% weight)
    if (data.length >= 2) {
      completion += 50;
    }
  
    // Check for UBO (50% weight)
    const hasUbo = data.some(partner => partner.isUbo !== null);
    if (hasUbo) {
      completion += 50;
    }
  
    return completion;
  };
  
  const calculateBankDetailsCompletion = (data: any): number => {
    if (data.bankingProofDocument) return 100;
  
    const requiredFields = [
      'bankName',
      'bankAccountHolderName',
      'bankAccountNumber',
      'ifscCode'
    ];
    const weightPerField = 100 / requiredFields.length;
  
    return requiredFields.reduce((total, field) => {
      return total + (data[field] ? weightPerField : 0);
    }, 0);
  };
  
  const calculateDocumentsCompletion = (data: any, businessType: BusinessTypeProps): number => {
    let totalWeight = 0;
    let completedWeight = 0;
  
    // Business proof (50%)
    totalWeight += 50;
    if (data.proofOfBusinessDocument || data.proofOfBusinessDocumentNumber) {
      completedWeight += 50;
    }
  
    // PAN verification (50%)
    totalWeight += 50;
    if (data.panDocument || (data.nameOnPan && data.panNumber)) {
      completedWeight += 50;
    }
  
    // Partnership deed (only for partnership/llp)
    if (["partnership", "llp"].includes(businessType)) {
      totalWeight += 50;
      if (data.partnershipDeedDocument) {
        completedWeight += 50;
      }
    }

    console.log("totalWeight", totalWeight);
    console.log("completedWeight", completedWeight);

    return totalWeight === 0 ? 0 : (completedWeight / totalWeight) * 100;
  };