import { Permissions } from "context/UserProvider";


export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };



  /**
 * Determines if KYC requirements are met based on user's active services and KYC status
 * @param userDetails - User details containing active services and KYC information
 * @returns boolean indicating if KYC requirements are satisfied
 */
export const determineKYCStatus = (userDetails: any): boolean => {
  if (userDetails && !Object.hasOwn(userDetails, "kycStatus")) {
    return true;
  }
    const validKYCStatuses = ['SUBMITTED', 'APPROVED', null];
    return validKYCStatuses.includes(userDetails.kycStatus);

  // const hasTorqueService = userDetails.activeServices.includes(Permissions.TORQUE);
  // const hasTreasuryService = userDetails.activeServices.includes(Permissions.TREASURY);

  // // Treasury-only users are considered KYC-done
  // if (!hasTorqueService && hasTreasuryService && userDetails.activeServices.length === 1) {
  //   return true;
  // }

  // // For Torque service, verify KYC status
  // if (hasTorqueService) {
  //   const validKYCStatuses = ['SUBMITTED', 'APPROVED', null];
  //   return validKYCStatuses.includes(userDetails.kycStatus);
  // }

  // return false;
};