import React from "react";
export type LogoProps = {
  withText?: boolean;
  invert?: boolean;
  width?: number;
  height?: number;
  responsive?: boolean;
} & React.HTMLAttributes<HTMLOrSVGElement>;
export const Logo = ({
  withText = true,
  invert = false,
  width = 180,
  height,
  responsive = false,
  ...props
}: LogoProps) => {
  const iconColor = "#216AFB";
  const textColor = invert ? "#424242" : "#FFF";
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2560 1440" height={height} style={responsive ? {
    width: "80%",
    maxWidth: width
  } : {
    width
  }} {...props} data-sentry-element="svg" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      {withText ? <g fill={textColor}>
          <path d="M935.576 843.897L935.576 615.991L892.6 615.991L892.6 843.897L935.576 843.897Z" />
          <path d="M1204.41 615.991L1162.74 615.991L1162.74 783.99C1162.74 785.618 1162.74 789.525 1159.16 789.525C1156.23 789.525 1155.58 786.269 1153.95 783.99L1057.25 631.293C1051.06 621.2 1041.62 613.386 1021.11 613.386C999.297 613.386 986.274 624.781 986.274 644.316L986.274 843.897L1027.95 843.897L1027.95 676.549C1027.95 671.665 1030.88 670.037 1032.51 670.037C1033.48 670.037 1034.13 670.688 1037.72 675.572L1133.76 827.292C1143.85 843.245 1156.55 846.501 1170.55 846.501C1189.11 846.501 1204.41 837.385 1204.41 814.269L1204.41 615.991Z" />
          <path d="M1298.09 843.897L1298.09 745.572L1399.02 745.572L1399.02 711.386L1298.09 711.386L1298.09 661.898C1298.09 658.967 1298.09 655.386 1300.69 652.781C1303.3 649.851 1305.9 650.177 1308.83 650.177L1436.46 650.177L1436.46 615.991L1287.99 615.991C1280.18 615.991 1271.39 616.642 1263.57 624.13C1256.74 630.642 1255.11 638.456 1255.11 645.944L1255.11 843.897L1298.09 843.897Z" />
          <path d="M1511.9 843.897L1511.9 615.991L1468.92 615.991L1468.92 843.897L1511.9 843.897Z" />
          <path d="M1780.73 615.991L1739.06 615.991L1739.06 783.99C1739.06 785.618 1739.06 789.525 1735.48 789.525C1732.55 789.525 1731.9 786.269 1730.27 783.99L1633.57 631.293C1627.39 621.2 1617.94 613.386 1597.43 613.386C1575.62 613.386 1562.6 624.781 1562.6 644.316L1562.6 843.897L1604.27 843.897L1604.27 676.549C1604.27 671.665 1607.2 670.037 1608.83 670.037C1609.81 670.037 1610.46 670.688 1614.04 675.572L1710.08 827.292C1720.18 843.245 1732.87 846.501 1746.87 846.501C1765.43 846.501 1780.73 837.385 1780.73 814.269L1780.73 615.991Z" />
          <path d="M1874.41 843.897L1874.41 615.991L1831.43 615.991L1831.43 843.897L1874.41 843.897Z" />
          <path d="M2034.18 843.897L2034.18 650.177L2123.38 650.177L2123.38 615.991L1902.32 615.991L1902.32 650.177L1991.2 650.177L1991.2 843.897L2034.18 843.897Z" />
          <path d="M2249.62 735.479L2177.99 615.991L2131.76 615.991L2228.13 774.223L2228.13 843.897L2271.1 843.897L2271.1 774.223L2367.48 615.991L2321.24 615.991L2249.62 735.479Z" />
        </g> : null}
      <path d="M481.753 480.786L230.292 662.523L180 944.278L481.753 802.586L783.505 944.278L735.728 662.523L481.753 480.786Z" fill={iconColor} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
    </svg>;
};