import AccountService, { AccountDetails, BankAccountStatus } from "apis/account";
import CommonService, { CountriesProps } from "apis/common";
import InvoiceService, { Balance } from "apis/invoice";
import { useUser } from "hooks/useUser";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "router/path";
import { BankAccountProps } from "sections/GlobalPayments/global-accounts/GlobalAccounts";
import { getUserName, handleLogout } from "services/authenticate";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Permissions } from "./UserProvider";
import { determineKYCStatus } from "utils/common";
const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};
interface InitContextType {
  //   init: Record<string, any>;
  //   setInit: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  countries: CountriesProps[];
  setCountries: React.Dispatch<React.SetStateAction<CountriesProps[]>>;
  bankAccounts: BankAccountProps[];
  setBankAccounts: React.Dispatch<React.SetStateAction<BankAccountProps[]>>;
  getBankAccountDetailsByCountryId: (countryId: number) => BankAccountProps | undefined;
  fetchData: () => void;
  useCountryCode: (countryId: number) => string | undefined;
  bankAccountDetail: AccountDetails[];
  isInitialized: boolean;
  cleanUp: () => void;
}
export const InitContext = createContext<InitContextType>({
  //   init: {},
  //   setInit: () => {},
  countries: [],
  setCountries: () => {},
  bankAccounts: [],
  setBankAccounts: () => {},
  getBankAccountDetailsByCountryId: () => undefined,
  fetchData: () => {},
  useCountryCode: () => undefined,
  isInitialized: false,
  bankAccountDetail: [],
  cleanUp: () => {}
});
export const InitProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    isAuthenticated,
    userDetails
  } = useUser();
  const [isInitialized, setIsInitialized] = useState(false);
  const [countries, setCountries] = useState<CountriesProps[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccountProps[]>([]);
  const [bankAccountDetail, setBankAccountDetail] = useState<AccountDetails[]>([]);

  // console.log("isAuthenticated", isAuthenticated, email);

  useEffect(() => {
    if (isAuthenticated && userDetails?.kycStatus === "APPROVED") {
      const initialize = async () => {
        try {
          await fetchData();
          await getBankAccountPaymentDetails();
        } catch (e) {
          console.log("error setting up init provider", e);
        } finally {
          setIsInitialized(true);
        }
      };
      initialize();
    } else {
      setIsInitialized(true);
    }
  }, [isAuthenticated, userDetails]);
  const useCountryCode = (countryId: number): string | undefined => {
    return useMemo(() => {
      const country = countries.find(c => c.id === countryId);
      return country?.currencySymbol;
    }, [countries, countryId]);
  };
  const fetchData = async () => {
    try {
      const countryResponse = await CommonService.GetCountries();
      const countries = countryResponse.data?.data;
      setCountries(countries);

      // console.log("countries -----", countries);

      const balanceResponse = await InvoiceService.GetBalance();
      const balances = Array.isArray(balanceResponse.data.available) ? balanceResponse.data.available : [balanceResponse.data.available];
      const bankAccountsResponse = await AccountService.getBankAccountStatus();
      const bankAccounts = bankAccountsResponse.data.data;
      const mergedData = mergeData(countries, balances, bankAccounts);
      // console.log("mergedData", mergedData);
      // @ts-ignore
      setBankAccounts(mergedData);
    } catch (err: any) {
      console.log("Error:", err);
      if (err.response.status === 401) {
        handleLogout();
      }
    } finally {}
  };

  // 2. Memoize the mergeData function
  const mergeData = useMemo(() => (countries: CountriesProps[], balances: Balance[], bankAccounts: BankAccountStatus[]) => {
    return bankAccounts.map(bankAccount => ({
      ...countries.find(ba => ba.id === bankAccount.countryId),
      ...balances.find(b => b.countryId === bankAccount?.countryId),
      ...bankAccount
    }));
  }, []);

  //utils

  //Get Bank Account DetailsBy Country Id
  const getBankAccountDetailsByCountryId = (countryId?: number) => {
    return bankAccounts.find(b => b.countryId === countryId);
  };
  const getBankAccountPaymentDetails = async () => {
    try {
      console.log("getBankAccountPaymentDetails");
      const response = await AccountService.getBankAccounts();
      setBankAccountDetail(response.data?.data);
    } catch (e) {}
  };
  const cleanUp = () => {
    setIsInitialized(false);
    setCountries([]);
    setBankAccounts([]);
  };
  const contextValue = {
    countries,
    setCountries,
    bankAccounts,
    setBankAccounts,
    getBankAccountDetailsByCountryId,
    fetchData,
    useCountryCode,
    isInitialized,
    cleanUp,
    bankAccountDetail
  };
  const content = <InitContext.Provider value={contextValue}>{children}</InitContext.Provider>;
  return process.env.REACT_APP_ENV === "production" ? <PostHogProvider options={posthogOptions} apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}>
      {content}
    </PostHogProvider> : content;
};