// permissions.ts
export const hasPermission = (
  userPermissions: number[], 
  requiredPermissions: number[], 
  exact: boolean = true
) => {
  if (requiredPermissions.length === 0) {
    return true;
  }
  return !exact 
    ? requiredPermissions.some((permission) => userPermissions.includes(permission))
    : requiredPermissions.every((permission) => userPermissions.includes(permission));
};
  