import { Navigate } from "react-router-dom";
import { PATH_DASHBOARD } from "./path";
import { usePageTracking } from "hooks/usePageTracking";
import { useUser } from "hooks/useUser";
import { determineKYCStatus } from "utils/common";
const PublicRouter = ({
  Element
}: {
  Element: React.ElementType;
}) => {
  usePageTracking();
  const token = localStorage.getItem("token");
  const {
    userDetails
  } = useUser();

  //@ts-ignore
  // const isKYCDone = userDetails?.isKYCDone;
  const isKYCDone = userDetails && determineKYCStatus(userDetails);
  if (token) {
    if (!isKYCDone) {
      return <Navigate to={PATH_DASHBOARD.app.kyc} />;
    }
    return <Navigate to={PATH_DASHBOARD.app.dashboard} />;
  }
  return <Element data-sentry-element="Element" data-sentry-component="PublicRouter" data-sentry-source-file="public_router.tsx" />;
};
export default PublicRouter;