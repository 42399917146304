import axios, { AxiosError } from "axios";
import Snackbar from "./snackbar";
import { getAPIError } from "./miscellaneous";
import * as Sentry from "@sentry/react";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const torquePrivateApi = axios.create({
  baseURL: process.env.REACT_APP_PROJECT_TORQUE_API_URL,
});

export const torquePublicApi = axios.create({
  baseURL: process.env.REACT_APP_PROJECT_TORQUE_API_URL,
});

export const userServiceApi = axios.create({
  baseURL: process.env.REACT_APP_USER_SERVICE_API_URL,
});

export const aggregatorApi = axios.create({
  baseURL: process.env.REACT_APP_AGGREGATOR_API_URL,
});

/// Add a response interceptor
torquePrivateApi.interceptors.response.use((response) => {
  // Handle successful responses
  return response;
}, getAPIError);

/// Add response interceptors for handling unauthorized responses
const handleUnauthorized = (error: AxiosError) => {
  Sentry.captureException(error);
  if (error.response?.status === 401) {
    // Clear localStorage and refresh page on unauthorized access
    localStorage.clear();
    window.location.reload();
  }
  return Promise.reject(error);
};


[api, torquePrivateApi, userServiceApi, aggregatorApi,torquePublicApi].forEach((instance) => {
  instance.interceptors.response.use(
    (response) => {
      const logEntry = {
        userId: localStorage.getItem("email"),
        timestamp: new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }),
        status: response.status,
        url: response.config.url,
        responseData: response.data,
      };
      console.log('API Response:', logEntry); // Replace with your logging mechanism
      return response;
    },
    handleUnauthorized
  );

  instance.interceptors.request.use((config) => {
    const logEntry = {
      userId: localStorage.getItem("email"), // Function to get authenticated user ID
      timestamp: new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }),
      method: config.method,
      url: config.url,
      payload: config.data,
    };
    console.log('API Request:', logEntry); 
    return config;
  });
});





export const setAuthorizationHeader = (token: string) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    torquePrivateApi.defaults.headers.common["Authorization"] =
      `Bearer ${token}`;
    userServiceApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    aggregatorApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
    delete torquePrivateApi.defaults.headers.common["Authorization"];
    delete userServiceApi.defaults.headers.common["Authorization"];
    delete aggregatorApi.defaults.headers.common["Authorization"];
  }
};
