import { useEffect } from "react";
import { useNavigate } from "react-router";
export const FreshStart: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("clearing data");
    localStorage.clear();
    document.cookie.split(";").forEach(function (cookie) {
      document.cookie = cookie.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
    });
    navigate("/");
  }, []);
  return <></>;
};