import { Navigate } from "react-router-dom";
import { useUser } from "hooks/useUser";
import { PATH_DASHBOARD } from "router/path";
import { Permissions } from "context/UserProvider";
import { determineKYCStatus } from "utils/common";
import LoadingScreen from "components/LoadingScreen";
interface KYCGuardProps {
  element: React.ComponentType;
  requireKYC?: boolean;
}
const KYCGuard = ({
  element: Element,
  requireKYC = true
}: KYCGuardProps) => {
  const {
    userDetails
  } = useUser();
  const isKYCDone = userDetails && determineKYCStatus(userDetails);
  console.log("KYC DONE:", isKYCDone);
  if (!userDetails) {
    return <LoadingScreen />;
  }

  // If KYC is not done and we're not already on the KYC page, redirect to KYC
  if (userDetails && !isKYCDone && requireKYC && window.location.pathname !== PATH_DASHBOARD.app.kyc) {
    return <Navigate to={PATH_DASHBOARD.app.kyc} />;
  }

  // If KYC is done and trying to access KYC page, redirect to dashboard
  if (isKYCDone && window.location.pathname === PATH_DASHBOARD.app.kyc) {
    return <Navigate to={PATH_DASHBOARD.app.dashboard} />;
  }
  return <Element data-sentry-element="Element" data-sentry-component="KYCGuard" data-sentry-source-file="KYCGuard.tsx" />;
};
export default KYCGuard;